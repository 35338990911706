.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  padding: 24px;
  font-size: calc(4px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#navbar, .page-body{
    padding-bottom: 128px;
}

@media (max-width: 769px){
    #navbar {
      padding-bottom: 256px;
    }

    .App-header {
      min-height: calc(var(--vh, 1vh) * 100);
      min-width: calc(var(--vw, 1vw) * 100);
    }

    .desktop {
      display: none;
    }
}

@media (min-width: 770px) {
  .mobile {
    display: none;
  }
}
